import * as React from "react";
import { FC } from "react";
import { WordsAboutWallpaintingsEntrypoint } from "../../../entrypoints/words-about-wallpaintings";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.plPL}
    entrypoint={"words-about-wallpaintings"}
    page={"kilka_slow_o_malarstwie_sciennym"}
  >
    <WordsAboutWallpaintingsEntrypoint lang={LANG.plPL} />
  </DefaultLayout>
);
